import {get,post} from "@/utils/axios"

const realList = p => post("/api-common/noticePortal/selectNoticeList", p)

const realInfo = p => get("/api-common/noticePortal/selectById", p)

const realSource = p => get("/api-common/noticePortal/selectSource", p)

export default {
    realList,
    realInfo,
    realSource,
}