<template>
  <el-row>
    <banner :banner-list="bannerList" :arrow-flag-hide="true"></banner>
    <el-col class="info">
      <el-col class="head-title">
        <el-col class="title">热门资讯动态</el-col>
        <el-col class="title en">HOT SOLUTIONS</el-col>
      </el-col>
      <el-col class="info-list">
        <el-col
             class="item"
             v-for="(item,idx) in realList"
             :key="idx"
        >
          <el-col class="left-img">
            <img :src="item.imgUrl">
          </el-col>
          <el-col class="right-content">
            <el-col class="title" @click.native="realInfo(item)">{{ item.title }}</el-col>
            <el-col class="time">{{ item.date }}</el-col>
            <el-col class="content">{{ item.summary }}</el-col>
          </el-col>
        </el-col>
      </el-col>
    </el-col>
  </el-row>
</template>

<script>
import banner from '@/compoents/banner/banner-components'
import api from '@/api/realTime.js'
export default {
  name: "infoIndex",
  components: {
    banner
  },
  data() {
    return {
      bannerList: [
        {
          img: require("@/img/information/banner.png"),
          writtenWords: {
            title: '伊登云资讯动态',
            describe: 'TRENDS OF EDEN CLOUD INFORMATION'
          },
          align: 'left',
          imgNum: 'single-img',
          color: 'white-font'
        }
      ],
      realList: [
          {
              imgUrl: require('@/img/information/img.png'),
              title: 'Veritas正式启动Desktop and Laptop Option 90天免费试用',
              date: '2020-04-18',
              content: '远程办公，安全先行，Veritas正式启动Desktop and Laptop Option 90天免费试用，针对于当前远程办公痛点问题，Veritas正式启动Desktop and Laptop Option 90天免费试用。Desktop and Laptop Option 90天免费......'
          },
          {
              imgUrl: require('@/img/information/img.png'),
              title: 'Veritas正式启动Desktop and Laptop Option 90天免费试用',
              date: '2020-04-18',
              content: '远程办公，安全先行，Veritas正式启动Desktop and Laptop Option 90天免费试用，针对于当前远程办公痛点问题，Veritas正式启动Desktop and Laptop Option 90天免费试用。Desktop and Laptop Option 90天免费......'
          },
          {
              imgUrl: require('@/img/information/img.png'),
              title: 'Veritas正式启动Desktop and Laptop Option 90天免费试用',
              date: '2020-04-18',
              content: '远程办公，安全先行，Veritas正式启动Desktop and Laptop Option 90天免费试用，针对于当前远程办公痛点问题，Veritas正式启动Desktop and Laptop Option 90天免费试用。Desktop and Laptop Option 90天免费......'
          },
      ]
    }
  },
    mounted() {
        this.$store.commit('setCheckIndex', 5);
        this.listReal();
    },
    methods: {
      listReal() {
          api.realList({
              pageNo: 1,
              pageSize: 10,
              type: 1
          }).then(res => {
              this.realList = res.data;
          })
      },
      realInfo(val) {
          sessionStorage.setItem('noticeId', val.noticeId);
          this.$router.push('realTime')
      }
    }
}
</script>

<style lang="less" scoped>
  .info {
    width: 1179px;
    margin: auto;
    float: unset;
    height: auto;

    .head-title {
      margin: 36px 0;

      .title {
        font-size: 24px;
        font-weight: 350;
        color: #F75145;
        width: auto;
      }
      .en {
        color: rgba(65, 65, 65, 0.1);
        margin-left: 33px;
      }
    }
    .info-list {
      margin-bottom: 80px;
      .item:hover {
        cursor: pointer;
        box-shadow: -4px 2px 20px 0px #c1c0c0;
      }

      .item {
        width: 1179px;
        height: 193px;
        background: #FFFFFF;
        border-radius: 10px;
        margin-bottom: 14px;

        .left-img {
          width: auto;
          img {
            width: 262px;
            height: 192px;
          }
        }
        .right-content {
          width: 835px;
          float: right;
          margin-left: 30px;
          margin-top: 38px;
          margin-right: 49px;
          .title {
            width: auto;
            font-size: 16px;
            font-weight: 550;
            color: #000000;
            line-height: 16px;
          }
            .title:hover {
                color: #F75145;
            }

          .time {
            width: auto;
            font-size: 14px;
            font-weight: 300;
            color: #414141;
            line-height: 14px;
            float: right;
          }

          .content {
            font-size: 14px;
            font-weight: 350;
            color: #000000;
            line-height: 28px;
            margin-top: 24px;
          }
        }
      }
    }
  }
</style>
